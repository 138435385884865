<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <b-row class="no-gutters">
        <b-col>
          <h1 class="mr-sm-4 header-tablepage">Brand</h1>
        </b-col>
      </b-row>
      <div class="title-tabs mt-3">Brand Field</div>
      <b-container class="no-gutters bg-white">
        <div class="py-3">
          <b-row>
            <b-col sm="6">
              <InputText
                textFloat="Name"
                placeholder="Name"
                v-model="form.name"
                isRequired
                type="text"
                :isValidate="$v.form.name.$error"
                :v="$v.form.name"
              />
            </b-col>
            <b-col sm="6">
              <InputText
                textFloat="Email"
                placeholder="Email"
                v-model="form.email"
                type="text"
                :isValidate="$v.form.email.$error"
                :v="$v.form.email"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <InputText
                textFloat="Telephone"
                placeholder="Telephone"
                v-model="form.tel"
                type="text"
                :isValidate="$v.form.tel.$error"
                :v="$v.form.tel"
              />
        
            </b-col>
             <b-col sm="6">
              <InputText
                textFloat="Fax"
                placeholder="Fax"
                v-model="form.fax"
                type="text"
              />
        
            </b-col>
            <b-col sm="12">
              <InputTextArea
                type="text"
                placeholder="Address"
                class="text-area"
                textFloat="Address"
                rows="12"
                v-model="form.address"
              />
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
    <FooterAction routePath="/setting/brand" @submit="saveForm()" />
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import OtherLoading from "@/components/loading/OtherLoading";
import InputTextArea from "@/components/inputs/InputTextArea";

const telephoneFormat = (val) => {
  const phoneNumberRegex = /^\d{1,20}(-\d{1,20})*$/;
  const numbers = val.split(',').map(num => num.trim());

  for (const number of numbers) {
    if (!phoneNumberRegex.test(number)) return false;

  
  }

  return true;
};



const TelephoneMinLength = (val) => {
  if (!val) return true; 
  const digitCount = val.replace(/-/g, '').length; 
  return digitCount > 9; 
};
export default {
  name: "ReceiptDetail",
  components: {
    OtherLoading,
    InputTextArea,
  },

  data() {
    return {
      isLoading: true,
      form: {
        name: "",
        address: "",
        tel: "",
        fax: "",
        email: "",
      },
      id: this.$route.params.id,
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        email: { email },
        tel: {
          TelephoneMinLength,
          telephoneFormat,
        },
      },
    };
  },
  async created() {
    await this.getDetail();
  },
  methods: {
    async getDetail() {
      this.isLoading = true;
      if (this.id > 0) {
        const res = await this.axios(`/Brand/List/${this.id}`);
        if (res.data.result == 1) {
          this.form = res.data.detail;
        }
      }
      this.isLoading = false;
    },
    async saveForm() {
      this.$v.$touch();
      if (!this.$v.$error) {
        if (this.id > 0) {
          this.isLoading = true;
          const res = await this.axios.put(
            `/Brand/UpdateBrand/${this.id}`,
            this.form
          );
          if (res.data.result == 1) {
            this.successAlert().then(() => this.$router.push("/setting/brand"));
          } else {
            this.errorAlert(res.data.message);
          }
        } else {
          this.isLoading = true;
          const res = await this.axios.post(`/brand/createBrand`, this.form);
          if (res.data.result == 1) {
            this.successAlert().then(() => this.$router.push("/setting/brand"));
          } else {
            this.errorAlert(res.data.message);
          }
        }
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ft-16 {
  font-size: 16px;
  color: var(--font-main-color);
}

.bg-gray {
  background-color: #f7f7f7;
}

.text-gray {
  color: #939393;
}

.input-container.error {
  border-color: red !important;
}

// ::v-deep .branch-modal > div {
//   margin: 0px 0px 4px 0px !important;
// }
.condition-title {
  color: var(--primary-color);
  font-size: 16px;
}

.btn-add-condition {
  color: white;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none !important;
}

.panelborder {
  border: 1px solid #d8dbe0;
}

.btn-icon {
  background-color: transparent;
  border: none;
  font-size: 16px;
}

.position-icon {
  position: absolute;
  z-index: 999;
  left: 90px;
}
</style>
